// export NODE_OPTIONS=--openssl-legacy-provider
// #ffd7be

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { findTimeZone, getZonedTime } from 'timezone-support';
import { toDate, format as formatTz, utcToZonedTime } from 'date-fns-tz';
import { nl as localeNL } from 'date-fns/locale';
import { getTheme } from '../actions/theme';
import { getTodoistTask } from '../actions/todoist';

import usCaFlag from './flags/us_ca.svg';
// import usTxFlag from './flags/us_tx.svg';
import inFlag from './flags/in.svg';
import jpFlag from './flags/jp.svg';
// import TuLogo from './TuLogo';


const wonderURL = 'https://wonder.kevinvandertoorn.com';

const AhLogoWrapper = styled.svg`
  width: 1.2em;
  height: 1.2em;
  position: relative;
  top: 0.3em;
  margin-right: 0em;
`;

function AhLogo({ color }) {
  return (
    <AhLogoWrapper viewBox='0 0 1000 1048'>
      <defs id="defs3860" />
      <g transform="translate(-23.868215,305.62409)" id="layer1">
        <g transform="matrix(15.504137,0,0,15.504137,-5866.0511,-7379.7381)" id="g3928">
          <path d="m 440.45789,488.69519 c 0,0 -14.08691,-26.23902 -14.08691,-26.23902 -1.48437,-2.75878 -4.88404,-3.76831 -7.59765,-2.25586 0,0 -25.78247,14.375 -25.78247,14.375 -1.16089,0.66406 -2.24365,1.82374 -2.73682,3.4668 0,0 -6.91895,24.02954 -6.91895,24.02954 -0.82886,2.87354 0.79224,5.88746 3.62183,6.73219 0,0 40.64454,11.56005 40.64454,11.56005 2.82715,0.84228 5.79345,-0.8081 6.62352,-3.6853 0,0 6.72364,-23.56202 6.72364,-23.56202 0.43701,-1.38305 0.19165,-3.0896 -0.49073,-4.42138 z" id="path3307" style={{ fill: color || '#00a0e2', fillOpacity: 1, fillRule: 'nonzero', stroke: 'white', strokeWidth: 3.5 }} />
          <path d="m 408.86244,493.74646 c 0,0 -4.47022,-8.71704 -7.35962,-8.71704 -2.23999,0.002 -2.43409,2.36817 -2.42798,8.07007 0.009,5.70435 0.31617,7.92602 2.42065,7.92602 2.86012,-0.002 7.36695,-7.27905 7.36695,-7.27905 z m 5.74707,-7.27295 c 2.29003,-3.29712 4.36035,-6.56127 8.23242,-6.56127 4.28712,-0.002 6.61012,3.58642 6.62475,6.60643 0,0.0305 -0.0183,19.3335 -0.0183,19.3335 0,0 -4.81446,0 -4.81446,0 0,0 -0.0208,-18.30078 -0.0208,-18.30078 0,-2.51343 -2.02026,-2.50854 -2.04834,-2.50854 -1.62841,0 -4.62159,4.08325 -7.95289,8.61694 0,0 0,12.19971 0,12.19971 0,0 -4.86816,0.005 -4.86816,0.005 0,0 -0.006,-5.64696 -0.006,-5.64696 0,0 -3.21899,5.64696 -8.03832,5.65307 -5.46754,10e-4 -7.323,-3.75855 -7.33643,-12.7649 -0.009,-8.58032 1.20849,-13.17139 7.06909,-13.17626 4.45801,-0.004 8.28735,6.4917 8.28735,6.4917 0,0 10e-4,-4.11378 10e-4,-4.11378 0,0 4.89135,-6.58813 4.89135,-6.58813 0,0 -0.0208,10.78003 -0.002,10.7544 z" id="path3309" style={{ fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} />
        </g>
      </g>
    </AhLogoWrapper>

  );
}

const TodoistLogoWrapper = styled.svg`
  width: auto;
  height: 3.8vw;
  position: relative;
  margin-right: 1.8vw;
  top: 0.2vw;
`;

function TodoistLogo({ color }) {
  // #DE483A
  const border = 3;
  return (
    <TodoistLogoWrapper xmlns="http://www.w3.org/2000/svg" viewBox={`${-border} -${border} ${32 + 2 * border} ${32 + 2 * border}`} fill="none" preserveAspectRatio="xMinYMid slice">
      <rect width={32 + 2 * border} x={-border} y={-border} height={32 + 2 * border} fill="black" rx={5.12 + border} />
      <rect width={32} height={32} fill={'#DE483A'} rx="5.12" />

      <path fill="white" d="m6.764 14.993 6.374-3.668.008-.005 6.568-3.78c.277-.16.29-.65-.02-.828l-.217-.124c-.317-.18-.727-.414-.902-.516a1.02 1.02 0 0 0-.997.012c-.155.09-10.501 6.038-10.847 6.235a1.349 1.349 0 0 1-1.339 0L-.072 9.144v2.699l.056.032c1.364.795 4.592 2.675 5.382 3.12.479.27.937.264 1.398-.002Z" />
      <path fill="white" d="m6.764 20.385 6.366-3.664.024-.014 6.56-3.775c.277-.16.29-.651-.02-.828l-.217-.124c-.316-.18-.727-.414-.902-.516a1.02 1.02 0 0 0-.997.012c-.155.089-10.501 6.038-10.847 6.234a1.349 1.349 0 0 1-1.339 0c-.326-.188-5.464-3.174-5.464-3.174v2.698l.056.033c1.365.795 4.592 2.674 5.382 3.12.479.27.937.264 1.398-.002Z" />
      <path fill="white" d="m13.139 22.108-6.375 3.669c-.461.266-.92.272-1.398.002-.79-.446-4.017-2.325-5.382-3.12l-.056-.033v-2.698l5.464 3.174c.413.239.925.236 1.339 0 .346-.196 10.692-6.145 10.847-6.235a1.02 1.02 0 0 1 .997-.012 125.007 125.007 0 0 0 1.12.64c.31.178.296.669.019.829l-6.575 3.784Z" />
    </TodoistLogoWrapper>


  );
}

// Styled components for better readability
const Outer = styled.div`
  height: 100%;
  width: 100%;
  background: url(${props => wonderURL + props.background});
  background-size: cover;
  /* Add a semi-transparent background color on top of the image */
  }
  /* background-position: top right;
  background-repeat: no-repeat; */
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  text-align: center;
  ${({ isAprilFool }) => isAprilFool && `
      transform: rotate(180deg);
      transform-origin: center;
  `}
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url(${props => wonderURL + props.foreground});
  background-size: cover;
  z-index: 999;
  pointer-events: none;
  ${({ theme }) => theme && theme.name && theme.name.includes('Pjotr') && `
    animation: moveUpDown 40s ease-in-out infinite normal;
  `}

  @keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    5% {
      transform: translateY(-2.5vh);
    }
    10% {
      transform: translateY(0);
    }
    15% {
      transform: translateY(-2.5vh);
    }
    20% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-2.5vh);
    }
    30%{
      transform: translateY(0);
    }
    35% {
      transform: translateY(-2.5vh);
    }
    40% {
      transform: translateY(0);
    }
    45% {
      transform: translateY(-2.5vh);
    }
    50% {
      transform: translateY(0);
    }
    55% {
      transform: translateY(-2.5vh);
    }
    60%{
      transform: translateY(0);
    }
    65% {
      transform: translateY(-2.5vh);
    }
    70% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(-200vh);
    }
    90% {
      transform: translateY(-70vh);
    }
    100% {
      transform: translateY(0vh);
    }
  }
`;

const Inner = styled.div`
  /* opacity: ${props => props.isPlaying ? 0 : 1};
  transition: opacity 2s ease; */
  /* display: ${props => props.isPlaying ? 'none' : 'block'}; */
`;

const Timezones = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const TimezoneClock = styled.div`
  font-size: ${({ theme }) => (theme.name && theme.name.includes('Danse Macabre')) ? '6vw' : '5vw'};
  font-family: ${({ theme }) => (theme.name && theme.name.includes('Danse Macabre')) ? 'Danse Macabre' : 'Ubuntu'}, sans-serif;
  font-weight: ${({ theme }) => (theme.name && theme.name.includes('Danse Macabre')) ? 'bold' : '500'};
  color: white;
  margin: 0 2rem;
  display: flex;
  align-items: center;
  text-shadow: 3px 3px 0 #18252d, -3px -3px 0 #18252d, 3px -3px 0 #18252d, -3px 3px 0 #18252d;
`;

const Flag = styled.img`
  border-radius: 0.6rem;
  height: 3.6vw;
  margin-right: 1.2rem;
  margin-top: ${({ theme }) => (theme.name && theme.name.includes('Danse Macabre')) ? '-0.3vw' : '0.25vw'};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  ${props => props.gray ? "filter: grayscale(1);" : ""}
`;

const BigClock = styled.a`
  font-size: 34vw;
  font-family: 'Salsa', sans-serif;
  font-weight: 400;
  color: white;
  text-decoration: none;
  line-height: 100%;
  text-shadow: 5px 5px 0 #18252d, -5px -5px 0 #18252d, 5px -5px 0 #18252d, -5px 5px 0 #18252d;
  
`;

const DateText = styled.div`
  font-size: ${({ theme }) => (theme.name && theme.name.includes('Danse Macabre')) ? '7vw' : '6vw'};
  font-family: ${({ theme }) => (theme.name && theme.name.includes('Danse Macabre')) ? 'Danse Macabre' : 'Ubuntu'}, sans-serif;
  font-weight: ${({ theme }) => (theme.name && theme.name.includes('Danse Macabre')) ? '500' : '500'};
  color: white;
  text-decoration: none;
  text-align: center;
  text-shadow: 3px 3px 0 #18252d, -3px -3px 0 #18252d, 3px -3px 0 #18252d, -3px 3px 0 #18252d;
`;

const Small = styled.sup`
  font-size: 3.5vw;
  position: relative;
  top: 0.14em;
`;

const TuLogoContainer = styled.div`
  width: 1.2em;
  height: 1.2em;
  position: relative;
  top: -0.05em;
  margin-right: 0.2em;
`;

const Colon = styled.span`
  position: relative;
  top: -0.07em;
  ${({ animate }) => animate && `
    animation: fadeOutIn 2s infinite;
  `}

  @keyframes fadeOutIn {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
`;

class Paddestoel extends Component {



  // Check if it's April Fool's day
  isAprilFool = () => {
    const { now } = this.props;
    return now && now.getMonth() === 3 && now.getDate() === 1;
  };

  // Helper function to format time strings
  getTimeString = (now, timezoneStr, forceTwoDigitHour = true) => {
    const timezone = findTimeZone(timezoneStr);
    const time = getZonedTime(now, timezone);
    const hours = ((forceTwoDigitHour ? '0' : '') + time.hours).slice(-2);
    const minutes = ('0' + time.minutes).slice(-2);
    return `${hours}:${minutes}`;
  };

  // Update time strings and date text
  update = () => {
    const { now } = this.props;

    if (!now) {
      return {
        timeStrings: {},
        dateText: null,
      };
    }

    // Define time strings for different timezones
    const timeStrings = {
      'us-ca': this.getTimeString(now, 'America/Los_Angeles'), // California
      // 'us-tx': this.getTimeString(now, 'America/Chicago'), // Texas
      // 'us-ny': this.getTimeString(now, 'America/New_York'), // New York
      nl: this.getTimeString(now, 'Europe/Amsterdam', false), // Netherland
      in: this.getTimeString(now, 'Asia/Kolkata'), // India
      // kr: this.getTimeString(now, 'Asia/Seoul'), // South Korea
      jp: this.getTimeString(now, 'Asia/Tokyo'), // Japan
    };

    // Format date text
    const options = {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    };
    const dateText = now.toLocaleDateString('nl', options).replace('.', '');

    return { timeStrings, dateText };
  };

  // Calculate time left until a specified shift
  timeLeft = () => {
    const { ahWorkShift } = this.props;

    if (ahWorkShift.start_time == null) {
      return "NA";
    }

    const amsterdamTimeZone = 'Europe/Amsterdam';
    const targetTime = toDate(ahWorkShift.start_time);
    const zonedTime = utcToZonedTime(targetTime, amsterdamTimeZone);

    const targetTimeEnd = toDate(ahWorkShift.end_time);
    const zonedTimeEnd = utcToZonedTime(targetTimeEnd, amsterdamTimeZone);

    // Create new variables for midnight times
    const currentTime = new Date();
    const currentMidnight = new Date(currentTime);
    const targetMidnight = new Date(zonedTime);

    // Set midnight times
    currentMidnight.setHours(0, 0, 0, 0);
    targetMidnight.setHours(0, 0, 0, 0);

    const differenceInMilliseconds = targetMidnight - currentMidnight;
    const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    if (differenceInDays >= 7) {
      return `${differenceInDays}d`;
      // return <>{differenceInDays}<Small>&thinsp;dagen</Small></>;
    }
    // if (differenceInDays >= 4) {
    //   const formattedTime = formatTz(zonedTime, 'EEEEEE', { timeZone: amsterdamTimeZone, locale: localeNL });
    //   return formattedTime;
    // }


    const formattedTimeStart = formatTz(zonedTime, 'EEEEEE HH:mm', { timeZone: amsterdamTimeZone, locale: localeNL }).replace(':00', '').replace(':30', '½');
    const formattedTimeEnd = formatTz(zonedTimeEnd, 'HH:mm', { timeZone: amsterdamTimeZone, locale: localeNL }).replace(':00', '').replace(':30', '½');
    const formattedTime = `${formattedTimeStart}–${formattedTimeEnd}`.replace('07–13½', '常');
    return formattedTime;
  };

  onClick = () => {
    this.props.getTheme();
    this.props.getTodoistTask();
  };

  // Helper function to calculate days until a specific date
  daysUntilTargetDate = (targetDate) => {
    const currentDate = new Date();
    const target = new Date(targetDate);
    const differenceInTime = target.getTime() - currentDate.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  };

  // Helper function to generate footer text
  getFooterText = (temperature, dateText, theme) => {
    const temperatureText = temperature ? <>{temperature}<Small>°C</Small>・</> : '';
    // const daysUntil = this.daysUntilTargetDate('2024-12-25');
    return (
      <DateText theme={theme}>
        {temperatureText}{dateText}・<AhLogo color={theme.color} /> {this.timeLeft()}
      </DateText>
    );
  };

  render() {
    const { timeStrings, dateText } = this.update();
    const temperature = (this.props.temperature != null) && Math.round(this.props.temperature);
    const { now, theme, todoist } = this.props; // Ensure theme is destructured here
    const isAprilFool = this.isAprilFool();

    // Determine if the colon should animate
    const shouldAnimateColon = now && now.getSeconds() >= 56;

    // Use the helper function to get footer text
    const footerText = this.getFooterText(temperature, dateText, theme);

    let timezonesComponent;

    if (todoist && todoist.available) {
      let time = '';
      if (todoist.task.due_time) {
        time = todoist.task.due_time + ' ';
      }
      timezonesComponent = (
        <Timezones>
          <TimezoneClock theme={theme}><TodoistLogo color={theme.color} />{time}{todoist.task.content}</TimezoneClock>
        </Timezones>
      );
    } else {
      timezonesComponent = (
        <Timezones>
          <TimezoneClock theme={theme}><Flag src={usCaFlag} gray={theme.id === 14} theme={theme} />{timeStrings['us-ca']}</TimezoneClock>
          <TimezoneClock theme={theme}><Flag src={inFlag} gray={theme.id === 14} theme={theme} />{timeStrings.in}</TimezoneClock>
          <TimezoneClock theme={theme}><Flag src={jpFlag} gray={theme.id === 14} theme={theme} />{timeStrings.jp}</TimezoneClock>
        </Timezones>
      );
    }

    // Handle undefined timeStrings.nl
    const [hours, minutes] = timeStrings.nl ? timeStrings.nl.split(':') : ['--', '--'];

    return (
      <Outer background={theme.background} isAprilFool={isAprilFool} onClick={this.onClick}>
        <Inner>
          {timezonesComponent}
          <BigClock>
            {hours}
            <Colon animate={shouldAnimateColon}>:</Colon>
            {minutes}
          </BigClock>
          {footerText}
        </Inner>
        <Overlay foreground={theme.foreground} theme={theme} />
      </Outer>
    );
  }
}

// Map state to props
const mapStateToProps = state => ({
  now: state.time.now,
  temperature: state.weather.temperature,
  feelsLike: state.weather.feelsLike,
  emoji: state.weather.emoji,
  ahWorkShift: state.ahwork,
  theme: state.theme,
  todoist: state.todoist,
});

const mapDispatchToProps = {
  getTheme,
  getTodoistTask,
};

// Connect component to Redux
export default connect(mapStateToProps, mapDispatchToProps)(Paddestoel);